import React from "react";
import styled from "styled-components";

const Page = styled.div`
  background-color: #f0f0f0; /* Grey background for the entire page */
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  font-family: "Arial", sans-serif;
  line-height: 1.6;
  padding: 30px;
  background-color: #ffffff;
  color: #333;
  max-width: 800px;
  width: 90%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: right; /* Align content to the right */
  direction: rtl; /* Ensure proper right-to-left text alignment for Arabic */
`;

const Title = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  color: #222;
  margin-bottom: 20px;
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  color: #444;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const Paragraph = styled.p`
  font-size: 1rem;
  margin-bottom: 10px;
`;

const List = styled.ul`
  padding-right: 20px; /* Adjust padding for right alignment */
  margin-bottom: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 8px;
`;

const Contact = styled.div`
  margin-top: 20px;
  color: #000;
  font-weight: bold;
`;

const Terms = () => {
  return (
    <Page>
      <Container>
        <Title> 🚗✨ الشروط والأحكام – مسابقة رمضان من موتور </Title>
        <Paragraph>
          مرحبًا بك في مسابقة رمضان الحصرية من موتور! 🎉 تأكد من الاطلاع على
          الشروط والأحكام لضمان فرصة عادلة للفوز.
        </Paragraph>

        <SectionTitle>تفاصيل البرنامج:</SectionTitle>
        <List>
          <ListItem>
            1- يتم تصنيف المشاركين إلى مجموعات وفقًا لحجم تعاملاتهم، وسيحصل كل
            مشترك على الجائزة المخصصة للمستوى الذي يبلغه بنهاية المسابقة.
          </ListItem>
          <ListItem>2- تبدأ المسابقة خلال النصف الثاني من رمضان.</ListItem>
          <ListItem>
            3- يتم احتساب النقاط بناءً على إجمالي قيمة الطلبات اللتي تم تسليمها
            حتى نهاية آخر يوم عمل في رمضان.
          </ListItem>
          <ListItem>
            4- الطلبات المُلغاة أو المُرتجعة لن تُحتسب ضمن إجمالي النقاط.
          </ListItem>
          <ListItem>
            5-لا يُمكن إرجاع المنتجات التي تم شراؤها خلال فترة المسابقة بعد
            انتهائها
          </ListItem>
          <ListItem>
            6- سيتم التواصل مع الفائزين لتحديد موعد ومكان تسليم الجوائز.
          </ListItem>
          <ListItem>
            7-يقر المتسابق على استخدام شركة موتور للتجارة الإلكترونية للمواد
            الصوتية والمرئية الخاصة بالمسابقة لأغراض التسويق والترويج لاحقًا.
          </ListItem>
        </List>

        <Contact>
          يمكنك التواصل معنا عبر الخط الساخن <strong>17110</strong> أو من خلال
          تطبيق واتساب.
        </Contact>
      </Container>
    </Page>
  );
};

export default Terms;
